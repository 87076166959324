<template>
<span class="inline-block flex h-10 rounded-full bg-halfColonialWhite max-w-min border border-halfColonialWhite cursor-pointer">
  <div class="flex items-center content-center w-28 py-3 px-1" :class="darkActive ? 'bg-redDamask rounded-full' : '' " v-on:click="modeSelect('dark')">
    <img src="../assets/darkInactive.svg" alt="dark mode" width="20" class="mr-3" :class="darkActive ? 'hidden' : '' ">
    <img src="../assets/darkActive.svg" alt="dark mode" width="20" class="mr-3 " :class="darkActive ? '' : 'hidden'">
    <p class="font-body text-bodyM" :class="darkActive ? 'text-halfColonialWhite' : 'text-sepiaBlack'">{{ $t('entity.dark') }}</p>
  </div>
  <div class="w-max flex items-center content-center w-28 py-3 px-1" :class="darkActive ? '' : 'bg-redDamask rounded-full' " v-on:click="modeSelect('light')">
    <img src="../assets/lightInactive.svg" alt="light mode" width="23" class="mr-2" :class="darkActive ? '' : 'hidden'">
    <img src="../assets/lightActive.svg" alt="light mode" width="23" class="mr-2" :class="darkActive ? 'hidden' : '' ">
    <p class="font-body text-bodyM" :class="darkActive ? 'text-sepiaBlack' : 'text-halfColonialWhite'">{{ $t('entity.light') }}</p>
  </div>
</span>
</template>

<script>
export default {
  name: 'dark-mode',
  props:{
    theme: {
      type: String,
      default: window.localStorage.theme ? window.localStorage.theme : 'dark'
    },
  },
  data() {
    return {
      currentMode: this.theme,
      darkActive: this.theme == 'dark' ? true : false 
    }
  },
  mounted() {
    localStorage.setItem('theme', this.theme);
    console.log(this.theme);
    if (this.theme == 'dark') {
      document.querySelector("html").classList.add("dark");
      document.querySelector("#app").classList.add("darkMode");
      this.darkActive = true
    } else if (this.theme == 'light') {
      document.querySelector("html").classList.remove("dark");
      document.querySelector("#app").classList.remove("darkMode");
      this.darkActive = false
    }
    window.localStorage.theme = this.theme;
    localStorage.setItem('theme', this.theme);
    this.currentMode = this.theme;
  },
  methods: {
    modeSelect: function(mode) {
      if(mode !== this.currentMode) {
        this.$emit('modeChanged', mode);
        if (mode == 'dark') {
          document.querySelector("html").classList.add("dark");
          document.querySelector("#app").classList.add("darkMode");
          this.darkActive = true
        } else if (mode == 'light') {
          document.querySelector("html").classList.remove("dark");
          document.querySelector("#app").classList.remove("darkMode");
          this.darkActive = false
        }
        window.localStorage.theme = mode;
        localStorage.setItem('theme', mode);
        this.currentMode = mode;
      }
    }
  },
  watch: {
    theme: function(newMode) { // watch it
      if (newMode == 'dark') {
          document.querySelector("html").classList.add("dark");
          document.querySelector("#app").classList.add("darkMode");
          this.darkActive = true
        } else if (newMode == 'light') {
          document.querySelector("html").classList.remove("dark");
          document.querySelector("#app").classList.remove("darkMode");
          this.darkActive = false
        }
    },
  }
}
</script>
